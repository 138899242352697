function checkboxStatusChange() {
  var multiselect = document.getElementById("mySelectLabel");
  var multiselectOption = document.getElementById("myoption");
  if(multiselect && multiselectOption){
    var values = [];
    var checkboxes = document.getElementById("mySelectOptions");
    var checkedCheckboxes = checkboxes.querySelectorAll('input[type=checkbox]:checked');

    for (const item of checkedCheckboxes) {
      var checkboxValue = item.getAttribute('id');
      values.push(checkboxValue);
    }

    var dropdownValue = "Select Skills";
    if (values.length > 0) {
      dropdownValue = values.join(', ');
    }

    multiselectOption.innerText = dropdownValue;
  }
}

function toggleCheckboxArea(onlyHide = false) {
  var checkboxes = document.getElementById("mySelectOptions");
  if(checkboxes)var displayValue = checkboxes.style.display;

  if (displayValue != "block") {
    if (onlyHide == false) {
      checkboxes.style.display = "block";
    }
  } else {
    checkboxes.style.display = "none";
  }
}
function initMultiselect() {
  checkboxStatusChange();

  document.addEventListener("click", function(evt) {
    var flyoutElement = document.getElementById('myMultiselect'),
      targetElement = evt.target; // clicked element

    do {
      if (targetElement == flyoutElement) {
        // This is a click inside. Do nothing, just return.
        //console.log('click inside');
        return;
      }

      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);

    // This is a click outside.
    toggleCheckboxArea(true);
    //console.log('click outside');
  });
}
document.addEventListener("turbolinks:load", () =>{
  initMultiselect();
  var dropdownbtn = document.getElementById("mySelectLabel");
  if(dropdownbtn){
    dropdownbtn.addEventListener('click', (event) => {
        toggleCheckboxArea();
    });
  }
  var checkboxElems = document.querySelectorAll("input[type='checkbox']");
  if(checkboxElems){
    for (var i = 0; i < checkboxElems.length; i++) {
      //checkboxElems[i].addEventListener("click", displayCheck);
      checkboxElems[i].addEventListener('change', (event) => {
          checkboxStatusChange();
      });
    }
  }
  const search = document.getElementById("search");
  const labels = document.querySelectorAll("#mySelectOptions > .label");
  if(search && labels){
    search.addEventListener("input", () => Array.from(labels).forEach((element) => element.style.display = element.childNodes[1].id.toLowerCase().includes(search.value.toLowerCase()) ? "block" : "none")); 
  }
});


