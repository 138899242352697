
function myFunction() {
	var text = document.getElementById('wiki-clone-url');
	text.select();
	document.execCommand('copy');
}
document.addEventListener("turbolinks:load", () => {
const clickButton = document.getElementById("copybutton");
    if(clickButton)clickButton.addEventListener('click', (event) => {
        myFunction()
    });
    $('.copy_text').click(function (e) {
   e.preventDefault();
   var copyText = $(this).attr('href');

   document.addEventListener('copy', function(e) {
      e.clipboardData.setData('text/plain', copyText);
      e.preventDefault();
   }, true);

   document.execCommand('copy');  
   alert('Link Copied');
});
   
});