$(document).on('turbolinks:load', function() {
    function selectskill(skill){
	  document.getElementById("add_skill").click();
	  var collection = document.querySelectorAll(".nested-fields");
	  var skill_select = collection[collection.length-1].querySelectorAll(".col-auto")[0].querySelectorAll(".form-control");
	  for ( var i = 0; i < skill_select[0].options.length; i++ ) {
	        if ( skill_select[0].options[i].value == skill.toString()) {
	            skill_select[0].options[i].selected = true;
	            return;
	        }
	    }
	}
	missingskills = document.getElementById("missingskills");
	  if(missingskills){
	    skills_list = missingskills.querySelectorAll("input");
	    for(var i=0;i<skills_list.length;i++){
	      selectskill(skills_list[i].value);
	    }
	  }
});