// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// For bootstrap
import 'slick-carousel/slick/slick';
import '../js/new_ui.js'
import '../js/tab.js'
import '../js/vtab.js'
import '../js/quiz.js'
import '../js/talent_show.js'
import '../js/skills.js'
import '../js/job.js'

import "bootstrap"
import './application.scss'

import "trix"
import "@rails/actiontext"

var $ = require( "jquery" )
require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
require("@nathanvda/cocoon")


Rails.start()
Turbolinks.start()
ActiveStorage.start()


document.addEventListener("turbolinks:load", () => {
  // $('[data-toggle="tooltip"]').tooltip();
  // $('[data-toggle="popover"]').popover();
  $(".loader_overlay").fadeOut(300);
})

