function opentab(evt, jobstatus) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(jobstatus).style.display = "block";
  evt.currentTarget.className += " active";
} 



document.addEventListener("turbolinks:load", () => {
  setTimeout(function() {
        var currentUrl= window.location.href;
        var buttons_dict={
                            "NewApplications": "defaultOpenvb" ,
                            "invited" : "invitedb",
                            "maybe" : "maybeb",
                            "rejected" : "rejectedb",
                            "hired" : "hiredb",
                          };
        if (currentUrl.indexOf('#') > -1) {
          var hashTag= currentUrl.substring(currentUrl.indexOf('#')+1);
          if(buttons_dict[hashTag] != undefined){
            const bt = document.getElementById(buttons_dict[hashTag]);
            bt.click();
          }
          else{
            if(document.getElementById("defaultOpenvb"))document.getElementById("defaultOpenvb").click();
          }
        }
        else {
            if(document.getElementById("defaultOpenvb"))document.getElementById("defaultOpenvb").click();
        }
    }, 2);

  const nv = document.getElementById("defaultOpenvb");
   if(nv){
    nv.addEventListener('click', (event) => {
        opentab(event, 'NV');
        location.href = '#NewApplications';
    });
   } 
  const invite = document.getElementById("invitedb");
    if(invite){invite.addEventListener('click', (event) => {
            opentab(event, 'invited');
            location.href = '#invited';
        });}
  const maybe = document.getElementById("maybeb");
    if(maybe){maybe.addEventListener('click', (event) => {
            opentab(event, 'maybe');
            location.href = '#maybe';
        });}
  const rejected = document.getElementById("rejectedb");
    if(rejected){rejected.addEventListener('click', (event) => {
            opentab(event, 'rejected');
            location.href = '#rejected';
        });}
  const hired = document.getElementById("hiredb");
    if(hired){hired.addEventListener('click', (event) => {
             opentab(event, 'hired');
             location.href = '#hired';
         }); } 
});


  