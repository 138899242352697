document.addEventListener("DOMContentLoaded", () => {
function convertHMS(value) {
  //this function converts seconds into string of HH:MM::SS to display
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours   = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
}
function countDown(secs, elem)
{
  //this function is used to count the countdown
   var element = document.getElementById(elem);
   element.innerHTML = "<b>"+convertHMS(secs)+"</b>";
   if(secs < 1) {
        document.getElementById("submit").click();
   }
   else
   {
        secs--;
        setTimeout(function(){countDown(secs,elem);},1000);
   }
}
function starttimer(total_time){
  countDown(total_time,"status");
}
  time = document.getElementById("time");
  if(time){
    starttimer(time.value.toString());
  }
   
});

//for timer
