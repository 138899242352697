var $ = require( "jquery" )
require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

var winW = $(window).outerWidth(),
    winH = $(window).outerHeight(),
    indexPage = $("#index_page").length,
    contactPage = $("#contact_page").length;

    $(function() {
        var sectionTitles = $('.content_left .content_text');
        var sectionContents = $('.content_right .content_item');
        ugScrollSpy(sectionTitles, sectionContents, "active", 82);
  });

  function ugScrollSpy(
	sectionTitles,
	sectionContents,
	activeClass,
	distanceFromTop
) {
	// Function that will run scroll of the window
	$(document).scroll(function () {
		sectionTitles.each(function () {
			// get index of each tiles
			var titleIndex = $(this).index();

			// get index of the content
			var contentIndex = sectionContents.eq(titleIndex);

			// get distance of content from top
			var containerOffset = contentIndex.offset().top;

			// get height of content
			var containerHeight = contentIndex.outerHeight();

			// get bottom of content
			var containerBottom = containerOffset + containerHeight;

			// get scroll position
			var scrollPosition = $(document).scrollTop();

			// If the left title is between the top of the content
			// and bottom of the content
			// add class active to the title else remove class
			if (
				scrollPosition < containerBottom - distanceFromTop &&
				scrollPosition >= containerOffset - distanceFromTop
			) {
				$(this).addClass(activeClass);
			} else {
				$(this).removeClass(activeClass);
			}
		});
	});

	// Function that will run on left title click
	sectionTitles.on("click", function () {
		var getChildIndex = $(this).index();

		var getDistanceofContentFromTop = $(
			sectionContents.eq(getChildIndex)
		).offset().top;

		$("html,body").animate(
			{
				scrollTop: getDistanceofContentFromTop - (distanceFromTop - 1),
			},
			1000
		);
	});
}
function transferHeight(e, t) {
    var i = $(e).outerHeight();
    $(t).outerHeight(i);
}

function equalHeight(e) {
    var t = 0;
    $(e).each(function() {
        var e = $(this).outerHeight();
        t < e && (t = e);
    }), $(e).outerHeight(t);
}

function getCurrentYear() {
    var e = new Date(), t = e.getFullYear();
    $(".current_year").text(t);
}

var ScrollOut = function() {
    "use strict";
    function e(e, t, i) {
        return e < t ? t : i < e ? i : e;
    }
    function t(e) {
        return +(0 < e) - +(e < 0);
    }
    function i(e) {
        return l[e] || (l[e] = e.replace(/([A-Z])/g, n));
    }
    function n(e) {
        return "-" + e[0].toLowerCase();
    }
    function o(e, t) {
        return e && 0 !== e.length ? e.nodeName ? [ e ] : [].slice.call(e[0].nodeName ? e : (t || c).querySelectorAll(e)) : [];
    }
    function r() {
        p.slice().forEach(function(e) {
            return e();
        }), a = p.length ? requestAnimationFrame(r) : 0;
    }
    function s() {}
    var a, l = {}, d = window, c = document.documentElement, u = function(e, t) {
        for (var n in t) e.setAttribute("data-" + i(n), t[n]);
    }, p = [], f = "scroll", h = "resize", m = "addEventListener", g = "removeEventListener", v = 0;
    return function(n) {
        var l, y, b, w, x, T, k, S = (n = n || {}).onChange || s, C = n.onHidden || s, $ = n.onShown || s, E = n.cssProps ? (l = n.cssProps,
        function(e, t) {
            for (var n in t) (!0 === l || l[n]) && e.style.setProperty("--" + i(n), (o = t[n],
            Math.round(1e4 * o) / 1e4));
            var o;
        }) : s, A = n.scrollingElement, N = A ? o(A)[0] : d, L = A ? o(A)[0] : c, H = ++v, j = function(e, t, i) {
            return e[t + H] !== (e[t + H] = JSON.stringify(i));
        }, D = function() {
            w = !0;
        }, _ = function() {
            w && (w = !1, b = o(n.targets || "[data-scroll]", o(n.scope || L)[0]).map(function(e) {
                return {
                    $: e,
                    ctx: {}
                };
            }));
            var i = L.clientWidth, r = L.clientHeight, s = t(-x + (x = L.scrollLeft || d.pageXOffset)), a = t(-T + (T = L.scrollTop || d.pageYOffset)), l = L.scrollLeft / (L.scrollWidth - i || 1), c = L.scrollTop / (L.scrollHeight - r || 1);
            y = {
                scrollDirX: s,
                scrollDirY: a,
                scrollPercentX: l,
                scrollPercentY: c
            }, b.forEach(function(o) {
                for (var s = o.$, a = s, l = 0, d = 0; l += a.offsetLeft, d += a.offsetTop, (a = a.offsetParent) && a !== N; ) ;
                var c = s.clientWidth || s.offsetWidth || 0, u = s.clientHeight || s.offsetHeight || 0, p = (e(l + c, x, x + i) - e(l, x, x + i)) / c, f = (e(d + u, T, T + r) - e(d, T, T + r)) / u, h = e((x - (c / 2 + l - i / 2)) / (i / 2), -1, 1), m = e((T - (u / 2 + d - r / 2)) / (r / 2), -1, 1), g = +(n.offset ? n.offset <= T : (n.threshold || 0) < p * f);
                o.ctx = {
                    elementHeight: u,
                    elementWidth: c,
                    intersectX: 1 === p ? 0 : t(l - x),
                    intersectY: 1 === f ? 0 : t(d - T),
                    offsetX: l,
                    offsetY: d,
                    viewportX: h,
                    viewportY: m,
                    visible: g,
                    visibleX: p,
                    visibleY: f
                };
            });
        }, M = (k = function() {
            if (b) {
                var e = {
                    scrollDirX: y.scrollDirX,
                    scrollDirY: y.scrollDirY
                };
                j(L, "_SA", e) && u(L, e), j(L, "_S", y) && E(L, y);
                for (var t = b.length - 1; -1 < t; t--) {
                    var i = b[t], o = i.$, r = i.ctx, s = r.visible;
                    j(o, "_SO", r) && E(o, r), j(o, "_SV", s) && (u(o, {
                        scroll: s ? "in" : "out"
                    }), r.index = t, S(o, r, L), (s ? $ : C)(o, r, L)), s && n.once && b.splice(t, 1);
                }
            }
        }, p.push(k), a || r(), function() {
            !(p = p.filter(function(e) {
                return e !== k;
            })).length && a && (a = 0, cancelAnimationFrame(a));
        });
        return D(), _(), d[m](h, _), N[m](f, _), {
            index: D,
            teardown: function() {
                M(), d[g](h, _), N[g](f, _);
            },
            update: _
        };
    };
}();


document.addEventListener("turbolinks:load", () => {
        getCurrentYear(),
        $("#nav-icon3").on("click", function () {
            $(this).toggleClass("open"), $(".sidebar_wrap").toggleClass("open");
        }),
        $(".sidebar_wrap .links a").on("click", function () {
            $("#nav-icon3").trigger("click");
        }),
        $(".logo_slider,.perks_slider,.snapshot_slider").slick({ arrows: !1, infinite: !0, speed: 2500, lazyLoad: "ondemand", autoplay: !0, autoplaySpeed: 0, cssEase: "linear", slidesToShow: 5, slidesToScroll: 1, variableWidth: !0 }),
        $(".social_media_slider").slick({
            arrows: !1,
            infinite: !0,
            speed: 2500,
            autoplay: !0,
            autoplaySpeed: 0,
            lazyLoad: "ondemand",
            cssEase: "linear",
            slidesToShow: 5,
            slidesToScroll: 1,
            variableWidth: !0,
            responsive: [
                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: !0, dots: !0 } },
                { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            ],
        }),
        $(".artificial_slider").slick({
            arrows: !0,
            infinite: !0,
            autoplay: !0,
            speed: 500,
            autoplaySpeed: 1e3,
            lazyLoad: "ondemand",
            variableWidth: !0,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $(".developer_title .arrow_prev"),
            nextArrow: $(".developer_title .arrow_next"),
            responsive: [
                { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
                { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1, variableWidth: !1 } },
                { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
            ],
        }),
        $(".developer_slider").slick({
            arrows: !0,
            infinite: !0,
            autoplay: !0,
            speed: 500,
            autoplaySpeed: 1e3,
            lazyLoad: "ondemand",
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: !0,
            prevArrow: $(".developer_title .arrow_prev"),
            nextArrow: $(".developer_title .arrow_next"),
            responsive: [
                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1, infinite: !0, dots: !0, variableWidth: !1 } },
                { breakpoint: 767, settings: { slidesToShow: 1 } },
            ],
        }),
        $(".case_study_slider").slick({
            arrows: !0,
            infinite: !0,
            autoplay: !0,
            speed: 500,
            autoplaySpeed: 1e3,
            lazyLoad: "ondemand",
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: !0,
            prevArrow: $(".developer_title .arrow_prev"),
            nextArrow: $(".developer_title .arrow_next"),
            responsive: [
                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1, infinite: !0, dots: !0, variableWidth: !1 } },
                { breakpoint: 992, settings: { slidesToShow: 2, variableWidth: !1 } },
                { breakpoint: 767, settings: { slidesToShow: 1, variableWidth: !1 } },
            ],
        }),
        $(".testi_Logo_slider").slick({
            arrows: !1,
            infinite: !0,
            autoplay: !0,
            speed: 500,
            autoplaySpeed: 1e3,
            lazyLoad: "ondemand",
            cssEase: "linear",
            slidesToShow: 5,
            slidesToScroll: 1,
            variableWidth: !0,
            responsive: [
                { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1, dots: !1, arrows: !1, autoplay: !0 } },
                { breakpoint: 760, settings: { slidesToShow: 1, slidesToScroll: 1, dots: !1, arrows: !1, autoplay: !0 } },
            ],
        }),
        $(".accordion_section .accordion_title").on("click", function () {
            $(this).hasClass("active")
                ? ($(this).removeClass("active"), $(this).parents(".accordion_section").find(".accordion_content").slideUp(200))
                : ($(this).addClass("active"), $(this).parents(".accordion_section").find(".accordion_content").slideDown(200));
        }),
        $(".everyday_slider").slick({
            arrows: !0,
            infinite: !0,
            autoplay: !0,
            speed: 500,
            autoplaySpeed: 1e3,
            lazyLoad: "ondemand",
            asNavFor: ".image_sec_slider",
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $(".everyday_reads_wrapps .arrow_prev"),
            nextArrow: $(".everyday_reads_wrapps .arrow_next"),
        }),
        $(".megamenu_slider").slick({
            arrows: !0,
            dots: !0,
            infinite: !0,
            speed: 300,
            lazyLoad: "ondemand",
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $(".left_item .arrow_prev"),
            nextArrow: $(".left_item .arrow_next"),
            rows: 8,
            responsive: [
                { breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 1 } },
                { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1, rows: 10, arrows: !1 } },
            ],
        }),
        $(".image_sec_slider").slick({ arrows: !1, infinite: !0, autoplay: !0, lazyLoad: "ondemand", fade: !0, speed: 300, asNavFor: ".everyday_slider", slidesToShow: 1, slidesToScroll: 1 }),
        $("[data-search]").on("keyup", function () {
            var e = $(this).val(),
                t = $(".code_item");
            "" != e ? (t.addClass("hidden"), $('.code_item[data-filter-name*="' + e.toLowerCase() + '"]').removeClass("hidden")) : t.removeClass("hidden");
        }),
        $(".find_genius").on('click', function (e) {
            e.preventDefault();
            $(".megamenu_sec").toggleClass("active");
        }),
        $(".back p").click(function () {
            $(".megamenu_sec").toggleClass("active");
        });
    var t = $(".count"),
        n = t.length,
        o = [];
    for (let i = 0; i < n; i++) o[i] = parseInt(t[i].innerHTML);
    for (let j = 0; j < n; j++)
        !(function (e, i, n) {
            var o = e;
            setInterval(function () {
                o < i && (o++, (t[n].innerHTML = o));
            }, 40);
        })(0, o[j], j);
    ScrollOut({ targets: [".fade_in", ".fadein_bottomToleft", ".expand_section", ".call_expand", ".c_to_l", ".c_to_r"], once: !0 });
}),

$(window).on("load", function () {
    setTimeout(function () {
        $(".loader_overlay").fadeOut(300),
        $("#index_page").length && $("#banner_section").addClass("animate_banner");
    }, 100),
        equalHeight(".testimonial_slider .item"),
        $(".client_slider").slick({
            arrows: !0,
            infinite: !0,
            autoplay: !0,
            speed: 300,
            slidesToShow: 3,
            lazyLoad: "ondemand",
            slidesToScroll: 1,
            variableWidth: !0,
            prevArrow: $(".testimonial_title_wrapp .arrow_prev"),
            nextArrow: $(".testimonial_title_wrapp .arrow_next"),
            responsive: [
                { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1, dots: !1, arrows: !1, autoplay: !0 } },
                { breakpoint: 760, settings: { slidesToShow: 3, slidesToScroll: 1, dots: !1, arrows: !1, autoplay: !0, centerMode: !0 } },
            ],
        }),
        equalHeight(".quality_text_wrap .quality_text .quality_text_item"),
        equalHeight(".artificial_intelligence_sec .artificial_inner_sec .developer_case"),
        equalHeight(".job_description_sec .artificial_inner_sec .developer_case");
}),
$(window).on("scroll", function (e) {
    var t = $(window).scrollTop();
    $("img[data-lazy-src]").each(function () {
        if (void 0 == $(this).attr("src")) {
            $(this).offset().top < t + 2 * winH && $(this).attr("src", $(this).attr("data-lazy-src"));
        }
    });
}),
$(window).on("resize", function () {}),
window.addEventListener("orientationchange", function () {
    location.reload();
}),
$(document).ready(function () {
    function e() {
        t().addClass("active"),
            i.addClass("transfomer"),
            setTimeout(function () {
                var e = $(".job-slide").slice(n);
                i.prepend(e), $(document).find(".job-slide.active").removeClass("active"), i.removeClass("transfomer");
            }, 300);
    }
    function t() {
        return $("#apply-job-slider > .job-slide").last();
    }
    var i = $("#apply-job-slider"),
        n = $("#apply-job-slider > .job-slide").length - 1;
    !(function () {
        setInterval(function () {
            e();
        }, 2e3);
    })();
}), 
window.addEventListener("load",function(){
   setTimeout(function open(event) {
    document.querySelector(".home_page_popup").style.display = "block";
 },3000)}),

$(function(){
    $("#close").on("click",()=>{
        $(".home_page_popup").css("display", "none");
    })
});


$(document).ready(function() {

    //create the validation

    function validateForm(fullname, email) {
        if (fullname.trim() === '' || fullname.length <= 4) {
            $('#fullname_error').text('Please enter your full name.');
            return false; // Validation failed
        }

        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email) || email.trim() === '') {
            $('#email_error').text('Please enter a valid email address.');
            return false; // Validation failed
        }

        // Validation passed
        return true;
    }
    function submitForm(data) {
        console.log("ajax",data)
      $.ajax({
        url: '/welcome/update_client_details',
        method: 'POST',
        data: data,
        success: function(response) {
          console.log('Form submitted successfully:', response);
          // Handle success response here
        },
        error: function(xhr, status, error) {
          // Handle error response here
          console.error('Error submitting form:', error);
        }
      });
    }
  //on click the modal talent page submit
    $('#talent_submit_btn').click(function(e) {
      e.preventDefault();
      var fullname = $('#fullname').val();
      var email = $('#email').val();

      if (!validateForm(fullname, email)) {
        // Validation failed, stop further execution
        return;
    }
    console.log("data")
      var data = {
        talent_info: {
          fullname: fullname,
          email: email
        }
      };
      submitForm(data);
    //   console.log(data)
    });
  //on click the modal client page submit
    $('#client_submit_btn').click(function(e) {
      e.preventDefault();
      var fullname = $('#fullname').val();
      var email = $('#email').val();
      var data = {
        client_info: {
          fullname: fullname,
          email: email
        }
      };
    
      submitForm(data);
    });
       $('#fullname').on('input', function() {
               if ($(this).val().trim() !== '') {
                 $('#fullname_error').text('');
              }
             });
        
           $('#email').on('input', function() {
              if ($(this).val().trim() !== '') {
                $('#email_error').text('');
              }
           });
  });

